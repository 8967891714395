import axios from "axios"
import { authHeader } from './auth-header';

var axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_HOST,
    headers: authHeader()
   });
   axiosInstance.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    console.log(error)
    if (!!error.response && 401 === error.response.status) {
        // handle error: inform user, go to login, etc
        localStorage.removeItem('user');
        location.reload(true);
    } else {
        return Promise.reject(error.response ? error.response.data.error : error);
    }
});
export default (options) => {
    axiosInstance.defaults.withCredentials = true
    return axiosInstance(options);
}