import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/forbidden/index')
    },
    {
        path: '/user',
        name: 'user',
        meta: {
            authRequired: true,
            permissionLevel : 3,
        },
        component: () => import('../views/pages/user/index')
    },
    {
        path: '/worker',
        name: 'worker',
        meta: {
            authRequired: true,
            permissionLevel : 1,
        },
        component: () => import('../views/pages/worker/index')
    },
    {
        path: '/vehicle_type',
        name: 'vehicle_type',
        meta: {
            authRequired: true,
            permissionLevel : 1,
        },
        component: () => import('../views/pages/vehicle_type/index')
    },
    {
        path: '/customer',
        name: 'customer',
        meta: {
            authRequired: true,
            permissionLevel : 1,
        },
        component: () => import('../views/pages/customer/index')
    },
    {
        path: '/product',
        name: 'product',
        meta: {
            authRequired: true,
            permissionLevel : 2,
        },
        component: () => import('../views/pages/product/index')
    },
    {
        path: '/transaction',
        name: 'transaction',
        meta: {
            authRequired: true,
            permissionLevel : 1,
        },
        component: () => import('../views/pages/transaction/index')
    },
    {
        path: '/report',
        name: 'report',
        meta: {
            authRequired: true,
            permissionLevel : 3,
        },
        component: () => import('../views/pages/report/index')
    },
    {
        path: '/event',
        name: 'event',
        meta: {
            authRequired: true,
            permissionLevel : 3,
        },
        component: () => import('../views/pages/event/index')
    },
    {
        path: '/resident',
        name: 'resident',
        meta: {
            authRequired: true,
            permissionLevel : 3,
        },
        component: () => import('../views/pages/resident/index')
    },
    {
        path: '/event_log',
        name: 'event_log',
        meta: {
            authRequired: true,
            permissionLevel : 3,
        },
        component: () => import('../views/pages/event_log/index')
    },
]
