
import { authHeader } from './auth-header';
import axiosInstance from "./client"

export const userService = {
    login,
    logout,
    register,
    getAll,
    createOrUpdate,
    getById,
    deleteById,
};


function login(email, password) {

    let formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    const requestOptions = {
        url : `/api/v1/login`,
        method: 'POST',
        data: formData
    };

    return axiosInstance(requestOptions)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        url : `/users/register`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(user)
    };
    return axiosInstance(requestOptions);
}

function getAll() {
    const requestOptions = {
        url : `/api/v1/user`,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance( requestOptions);
}

function getById(id) {
    const requestOptions = {
        url : '/api/v1/user/' + id,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}


function createOrUpdate(jsonData) {

    let formData = new FormData();

    jsonData["ID"] = jsonData["id"]
    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key]);
    }
    let rMethod = jsonData["id"] != null  ? "PUT" : "POST"
    const requestOptions = {
        url : '/api/v1/user',
        method: rMethod,
        data: formData,
        headers: authHeader()
    };

    return axiosInstance(requestOptions);
}


function deleteById(id) {
    const requestOptions = {
        url: `/api/v1/user/` + id,
        method: 'DELETE',
        headers: authHeader()
    };
    return axiosInstance(requestOptions);
}

